@import './variables.scss'; // sass variables

.spacer {
  height: 1px;
  border-bottom: 1px solid;
}

.expanded {
  display: block;
}

.hidden {
  display: none;
}

a.clickable {
  color: $blue;
  &:not([href]) {
    color: $blue;
  }
}

.nowrap {
  flex-wrap: nowrap;
}

.wizefi-max-width {
  max-width: $max-width;
}
