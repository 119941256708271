@import './variables.scss';

@font-face {
  font-family: 'SharpSansDispNo1';
  src: url('../assets/fonts/sharpSans/SharpSansDispNo1-Light.otf') format('opentype');
  font-weight: 100;
}
@font-face {
  font-family: 'SharpSansDispNo1';
  src: url('../assets/fonts/sharpSans/SharpSansDispNo1-LightIt.otf') format('opentype');
  font-weight: 100;
  font-style: italic;
}
@font-face {
  font-family: 'SharpSansDispNo1';
  src: url('../assets/fonts/sharpSans/SharpSansDispNo1-Light.otf') format('opentype');
  font-weight: 200;
}
@font-face {
  font-family: 'SharpSansDispNo1';
  src: url('../assets/fonts/sharpSans/SharpSansDispNo1-LightIt.otf') format('opentype');
  font-weight: 200;
  font-style: italic;
}
@font-face {
  font-family: 'SharpSansDispNo1';
  src: url('../assets/fonts/sharpSans/SharpSansDispNo1-Light.otf') format('opentype');
  font-weight: 300;
}
@font-face {
  font-family: 'SharpSansDispNo1';
  src: url('../assets/fonts/sharpSans/SharpSansDispNo1-LightIt.otf') format('opentype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'SharpSansDispNo1';
  src: url('../assets/fonts/sharpSans/SharpSansDispNo1-Medium.otf') format('opentype');
  font-weight: 400;
}
@font-face {
  font-family: 'SharpSansDispNo1';
  src: url('../assets/fonts/sharpSans/SharpSansDispNo1-MediumIt.otf') format('opentype');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'SharpSansDispNo1';
  src: url('../assets/fonts/sharpSans/SharpSansDispNo1-Medium.otf') format('opentype');
  font-weight: 500;
}
@font-face {
  font-family: 'SharpSansDispNo1';
  src: url('../assets/fonts/sharpSans/SharpSansDispNo1-MediumIt.otf') format('opentype');
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: 'SharpSansDispNo1';
  src: url('../assets/fonts/sharpSans/SharpSansDispNo1-Semibold.otf') format('opentype');
  font-weight: 600;
}
@font-face {
  font-family: 'SharpSansDispNo1';
  src: url('../assets/fonts/sharpSans/SharpSansDispNo1-SemiboldIt.otf') format('opentype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'SharpSansDispNo1';
  src: url('../assets/fonts/sharpSans/SharpSansDispNo1-Bold.otf') format('opentype');
  font-weight: 700;
}
@font-face {
  font-family: 'SharpSansDispNo1';
  src: url('../assets/fonts/sharpSans/SharpSansDispNo1-BoldIt.otf') format('opentype');
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: 'SharpSansDispNo1';
  src: url('../assets/fonts/sharpSans/SharpSansDispNo1-Bold.otf') format('opentype');
  font-weight: 800;
}
@font-face {
  font-family: 'SharpSansDispNo1';
  src: url('../assets/fonts/sharpSans/SharpSansDispNo1-BoldIt.otf') format('opentype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'SharpSansDispNo1';
  src: url('../assets/fonts/sharpSans/SharpSansDispNo1-Extrabold.otf') format('opentype');
  font-weight: 900;
}
@font-face {
  font-family: 'SharpSansDispNo1';
  src: url('../assets/fonts/sharpSans/SharpSansDispNo1-ExtraboldIt.otf') format('opentype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Financier';
  src: url('../assets/fonts/financier/FinancierDisplayTest-Medium.otf') format('opentype');
  font-weight: normal;
}

@font-face {
  font-family: 'Financier';
  src: url('../assets/fonts/financier/FinancierDisplayTest-Semibold.otf') format('opentype');
  font-weight: bold;
}

@font-face {
  font-family: 'Financier';
  src: url('../assets/fonts/financier/FinancierDisplayTest-Bold.otf') format('opentype');
  font-weight: bolder;
}

@font-face {
  font-family: 'Financier';
  src: url('../assets/fonts/financier/FinancierDisplayTest-Light.otf') format('opentype');
  font-weight: lighter;
}

* {
  font-family: 'SharpSansDispNo1', sans-serif;
}
