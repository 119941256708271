$yellow: #ff971d;
$red: #cb3d4d;
$positive-color: rgb(124, 214, 172);
$negative-color: rgb(235, 132, 132);
$green: #178d23;
$light-blue: #eaefff;
$blue: #4272ff;
$dark-blue: #2b5b93;
$purple: #5a4dbf;
$light-gray: #ddd;
$light-gray-2: #fafafa;
$middle-gray: #757575;
$dark-gray: #212121;

$max-width: 42rem;
