/**
Global Styles and imports only! component styles belong in component directories.
*/

@import './vendor.scss'; // 3rd party libs
@import './variables.scss'; // sass variables
@import './utilities.scss'; // any utilities, functions, or special cases we need!
@import './typography.scss'; // fonts and things
@import 'https://fonts.googleapis.com/icon?family=Material+Icons';

@import 'https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200';

@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'reset.scss';

:root {
  --light-gray: #{$light-gray};
  --light-gray-2: #{$light-gray-2};
  --middle-gray: #{$middle-gray};
  --dark-gray: #{$dark-gray};
  --light-blue: #{$light-blue};
  --dark-blue: #{$dark-blue};
  --blue: #{$blue};
  --green: #{$green};
  --light-green: #dcffea;
  --positive: #{$positive-color};
  --red: #{$red};
  --light-red: #ffe5e5;
  --purple: #{$purple};
  --yellow: #ff971d;
  --light-yellow: #fff3ca;
}

.material-symbols-outlined {
  font-variation-settings: 'FILL' 0, 'wght' 400, 'GRAD' 0, 'opsz' 48;
}

input[type='button']::-moz-focus-inner {
  border: 0;
}

html {
  min-height: 100%;
}
body {
  background-color: #f0f0f5;
  // height: 100%;
}

// Scroll Bar Styling

/* Scrollbar width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Scrollbar Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px var(--light-gray);
  border-radius: 10px;
}

/* Scrollbar Handle */
::-webkit-scrollbar-thumb {
  background: #92a0b1;
  border-radius: 10px;
}

/* Scrollbar Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #92a0b1;
}

// Scroll Bar Styling Ends
// TODO PA I am not sure what is causing us to have to do this. The code needs to be refactored to flex vertically properly.
@media (max-width: 767px) {
  html, body {
    //background-color: #2f2f2f;
    background-color: #f0f0f5;
    // height: 100%;
  }
}
@media (min-width: 768px) {
  html, body {
    background-color: #f0f0f5;
    // background-color: #393939;
    height: 100%;
  }
}

mat-progress-bar.mat-progress-bar {
  height: 12px;
  border-radius: 6px;

  .mat-progress-bar-buffer {
    background-color: $light-gray-2;
  }
}

// html,
// body {
//   //height: 100%;
// }

input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
  /* Firefox */
}

input {
  border: inherit;
  background-color: inherit;
  overflow: hidden !important;
}

.heading {
  margin: 0 1rem 1rem 1rem;
}

.mat-tooltip {
  font-size: 14px;
  background-color: rgba(0, 0, 0, 0.75);
  padding: 8px;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-expand {
  flex: 1;
}

.justify-space-between {
  justify-content: space-between;
}

.align-items-start {
  align-items: flex-start;
}

.align-items-center {
  align-items: center;
}

.align-items-baseline {
  align-items: baseline;
}

.gray {
  color: $middle-gray;
}

strong {
  font-weight: bold;
}

.dialog-no-padding mat-dialog-container {
  padding: 0;
}

mat-dialog-container.mat-dialog-container {
  border-radius: 16px;
}

.cdk-overlay-pane {
  // Allows dialog to be full width
  max-width: 100vw !important;
}

.mat-dialog-content {
  // Allows action buttons to be shown when screen height is smaller than dialog height
  max-height: calc(100vh - 148px) !important;
}

.mat-dialog-actions {
  // Used to avoid smaller bottom margin on mat-dialog actions
  padding-bottom: 0px !important;
  margin-bottom: 0px !important;
}

snack-bar-container.success-snackbar {
  background-color: #097e3f;
}

snack-bar-container.info-snackbar {
  background-color: #92a0b1;
}

snack-bar-container.mat-snack-bar-container {
  max-width: 650px;
}

.mat-select-panel {
  // Overrides default height of angular material select panel
  max-height: 50vh !important;
}

mat-form-field.prefix-align-left .mat-form-field-label-wrapper {
  margin-left: -13px;
}

.mat-form-field-appearance-outline .mat-form-field-prefix,
.mat-form-field-appearance-outline .mat-form-field-suffix {
  top: 0em !important;
}

input.text-right {
  text-align: end;
}

.message-link {
  color: var(--yellow);
  text-decoration: underline;
  cursor: pointer;
}

.message-link-transactions-modified {
  @extend .message-link;
}

.message-link-accounts-disconnected {
  @extend .message-link;
}


.highlight-row {
  background-color: #fff3ca;
}
